import React, { Component, Fragment } from "react";
import "./App.css";
import logo from "./vriddhi_logo.png";
import moment from 'moment'
import {
  Container,
  Row,
  Col,
  Table,
  Image,
  Form,
  Button,
  Spinner,
  Alert,
  Navbar,
} from "react-bootstrap";

import axios from "axios";
import Moment from "react-moment";

// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

import Pdf from "react-to-pdf";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { propTypes } from "react-bootstrap/esm/Image";
const ref = React.createRef();
const options = {
  orientation: "landscape",
};


var manageLoader=false;
// const baseUrl

//const data = require('./data.json');
// const abcasa = localStorage.getItem("memId");
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberno: "",
      memberType: "",
      memberDataList: [],
      accountDetails: [],
      isLoading: false,
      showTable: false,
      forData: false,
      isOpenValue: "notfound",
      accountStateData: '',
      memberDetails: {
        memName: "",
        fieldOfficer: "",
        centerName: "",
        regMobile: "",
      },
      memberno_Flag: false,
      actived: null,
      divIdToPrint: document.getElementsByClassName("divIdToPrint")[0],
      notMemberMsg: false,
      hideBox: false,
      hideTable: false,
      userData: [],
      optionsList: [],
      optionsListVip: "",
      memberId: [],
      constId: [],
      memberIds: localStorage.getItem("memId"),
    };
  }
  componentDidMount() {
    var mobileNumber = localStorage.getItem("memberno");
    var memberType = localStorage.getItem("memberType");

    if (mobileNumber && memberType.toLowerCase() == "member") {
      this.getData();
    }
  }

  handleMemberNo = (key, value) => {
    this.setState({
      [key]: value,
    });
    this.setState({
      // memberIds: abcasa,
      isLoading: true
    })

    // this.setState({

    // })
    var obj = {
      // agentId: memId,
      memberno: value
    }
    console.log(obj);
    manageLoader=true;
    axios
      .post(
        "https://x70xghxttc.execute-api.ap-south-1.amazonaws.com/PROD/getmemberdetails",
        obj
      )
      .then((res) => {
        console.log(res);
        manageLoader=false;
        if (
          res &&
          res.data &&
          res.data.status &&
          res.data.status === "success"
        ) {
          if (res.data.result.length != 0) {
            //localStorage.setItem("memberno", this.state.memberno);
            this.setState({
              isLoading: false,
              memberDetails: res.data.result.userData,
              memberDataList: res.data.result.accountsData,
              forData: true,
              showTable: false,
              notMemberMsg: false,
            });
            // const instance = this._typeahead.getInstance()
            // instance.clear()
            // instance.focus()
          } else {
            //localStorage.setItem("memberno", '');
            this.setState({
              isLoading: false,
              memberDetails: {
                memName: "",
                fieldOfficer: "",
                centerName: "",
                regMobile: "",
              },
              memberDataList: [],
              forData: false,
              showTable: false,
              notMemberMsg: true,
            });
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };


  handleEvent = (value) => {

    this.getDatas();
  }

  handleSubmitMemberNo = (value) => {

    var isAllowFlag = true;
    let ifinfo = localStorage.getItem('memId')
    this.setState({
      actived: null,
    });

    if (ifinfo == "") {
      isAllowFlag = false;
      this.setState({
        memberno_Flag: true,
      });
    }
    else {
      this.setState({
        memberno_Flag: false,
      });
    }

    if (isAllowFlag === true) {

      if (ifinfo == "M00001" || "M00002" || "M00004" || "M00005" || "M00018" || "M00028" || "M52432" || "M00009" || "M55281" || "M00058") {
        this.getDatasInfo(value);
      }

      this.getDatas();
    }
  };



  // getData() {
  //   let memId = localStorage.getItem("memId");
  //   let infos = localStorage.getItem("userId");
  //   let splitData = infos.split(" - ")
  //   
  //   // if(splitData.length > 1) {
  //   //  window.alert()
  //   // }else {

  //     let dataes = splitData[0];

  //     var requestBodyVip = {
  //       memberno: dataes,
  //     };


  //   this.setState({
  //     isLoading: true,
  //     // memberno_Flag:false
  //   });

  //   console.log(requestBody);
  //   if(memId == "M00001" || "M00002" || "M00004" || "M00005" || "M00018" || "M00028" || "M52432")
  //   {

  //     axios
  //     .post(
  //       "https://x70xghxttc.execute-api.ap-south-1.amazonaws.com/PROD/getmemberdetails",
  //       requestBodyVip
  //     )
  //     .then((res) => {
  //       console.log(res);

  //       if (
  //         res &&
  //         res.data &&
  //         res.data.status &&
  //         res.data.status === "success"
  //       ) {
  //         if (res.data.result.length != 0) {
  //           //localStorage.setItem("memberno", this.state.memberno);
  //           this.setState({
  //             isLoading: false,
  //             memberDetails: res.data.result.userData,
  //             memberDataList: res.data.result.accountsData,
  //             forData: true,
  //             showTable: false,
  //             notMemberMsg: false,
  //           });
  //         } else {
  //           //localStorage.setItem("memberno", '');
  //           this.setState({
  //             isLoading: false,
  //             memberDetails: {
  //               memName: "",
  //               fieldOfficer: "",
  //               centerName: "",
  //               regMobile: "",
  //             },
  //             memberDataList: [],
  //             forData: false,
  //             showTable: false,
  //             notMemberMsg: true,
  //           });
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  //   }else{
  //     let data = this.state.memberId.split(" - ")
  //     let mId = data[0];
  //     let infoss = localStorage.getItem("userId");
  //     let splitDatas = infoss.split(" - ")
  //     
  //   let dataess = splitDatas[0];

  //     var requestBody = {
  //       memberno: dataess,
  //     };
  //     axios
  //     .post(
  //       "https://x70xghxttc.execute-api.ap-south-1.amazonaws.com/PROD/getmemberdetails",
  //       requestBody
  //     )
  //     .then((res) => {
  //       console.log(res);

  //       if (
  //         res &&
  //         res.data &&
  //         res.data.status &&
  //         res.data.status === "success"
  //       ) {
  //         if (res.data.result.length != 0) {
  //           //localStorage.setItem("memberno", this.state.memberno);
  //           this.setState({
  //             isLoading: false,
  //             memberDetails: res.data.result.userData,
  //             memberDataList: res.data.result.accountsData,
  //             forData: true,
  //             showTable: false,
  //             notMemberMsg: false,
  //           });
  //         } else {
  //           //localStorage.setItem("memberno", '');
  //           this.setState({
  //             isLoading: false,
  //             memberDetails: {
  //               memName: "",
  //               fieldOfficer: "",
  //               centerName: "",
  //               regMobile: "",
  //             },
  //             memberDataList: [],
  //             forData: false,
  //             showTable: false,
  //             notMemberMsg: true,
  //           });
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  //   }
  //   }
  getDatas(datas) {

    if (datas != null) {

      let memId = localStorage.getItem("memId");
      // let infos = localStorage.getItem("userId");
      let info = datas[0].split(" - ")
      // if(splitData.length > 1) {
      //  window.alert()
      // }else {
      let dataes = info[0];

      var requestBodyVip = {
        memberno: dataes,
      };



      console.log("true")
      this.setState({
        
        isLoading: true,
        // memberno_Flag:false
      });

      // console.log(requestBody);
      manageLoader=true;

      axios
        .post(
          "https://x70xghxttc.execute-api.ap-south-1.amazonaws.com/PROD/getmemberdetails",
          requestBodyVip
        )
        .then((res) => {
          console.log(res);
          manageLoader=false;

          if (
            res &&
            res.data &&
            res.data.status &&
            res.data.status === "success"
          ) {
            if (res.data.result.length != 0) {
              //localStorage.setItem("memberno", this.state.memberno);
              this.setState({
                isLoading: false,
                memberDetails: res.data.result.userData,
                memberDataList: res.data.result.accountsData,
                forData: true,
                showTable: false,
                notMemberMsg: false,
              });
              // const instance = this._typeahead.getInstance()
              // instance.clear()
              // instance.focus()
            } else {
              //localStorage.setItem("memberno", '');
              this.setState({
                isLoading: false,
                memberDetails: {
                  memName: "",
                  fieldOfficer: "",
                  centerName: "",
                  regMobile: "",
                },
                memberDataList: [],
                forData: false,
                showTable: false,
                notMemberMsg: true,
              });
            }
          }
        })
        .catch((err) => {

          console.log("err", err);
        });


    }

    else {

      this.render(
        <Alert variant="danger">Member does not exist.</Alert>
      )
      // return 
    }

  }
  getDatasInfo(datas) {

    localStorage.setItem("setquery", datas);
    //    
    let memId = localStorage.getItem("memId");
    let queryinfo = localStorage.getItem("setquery")
    var requestBodyVip = {
      memberno: queryinfo,
    };
    this.setState({
      isLoading: true,
      // memberno_Flag:false
    });

    console.log(requestBody);
    if (memId == "M00001" || "M00002" || "M00004" || "M00005" || "M00018" || "M00028" || "M52432" || "M00009" || "M55281" || "M00058") {

      axios
        .post(
          "https://x70xghxttc.execute-api.ap-south-1.amazonaws.com/PROD/getmemberdetails",
          requestBodyVip
        )
        .then((res) => {
          console.log(res);

          if (
            res &&
            res.data &&
            res.data.status &&
            res.data.status === "success"
          ) {
            if (res.data.result.length != 0) {
              //localStorage.setItem("memberno", this.state.memberno);
              this.setState({
                isLoading: false,
                memberDetails: res.data.result.userData,
                memberDataList: res.data.result.accountsData,
                forData: true,
                showTable: false,
                notMemberMsg: false,
              });
              // const instance = this._typeahead.getInstance()
              // instance.clear()
              // instance.focus()
            } else {
              //localStorage.setItem("memberno", '');
              this.setState({
                isLoading: false,
                memberDetails: {
                  memName: "",
                  fieldOfficer: "",
                  centerName: "",
                  regMobile: "",
                },
                memberDataList: [],
                forData: false,
                showTable: false,
                notMemberMsg: true,
              });
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      let data = this.state.memberId.split(" - ")
      let mId = data[0];
      let infoss = localStorage.getItem("userId");
      let splitDatas = infoss.split(" - ")

      let dataess = splitDatas[0];

      var requestBody = {
        memberno: dataess,
      };
      axios
        .post(
          "https://x70xghxttc.execute-api.ap-south-1.amazonaws.com/PROD/getmemberdetails",
          requestBody
        )
        .then((res) => {
          console.log(res);
          manageLoader=false;
          if (
            res &&
            res.data &&
            res.data.status &&
            res.data.status === "success"
          ) {
            if (res.data.result.length != 0) {
              //localStorage.setItem("memberno", this.state.memberno);
              this.setState({
                isLoading: false,
                memberDetails: res.data.result.userData,
                memberDataList: res.data.result.accountsData,
                forData: true,
                showTable: false,
                notMemberMsg: false,
              });
              // const instance = this._typeahead.getInstance()
              // instance.clear()
              // instance.focus()
            } else {
              //localStorage.setItem("memberno", '');
              this.setState({
                isLoading: false,
                memberDetails: {
                  memName: "",
                  fieldOfficer: "",
                  centerName: "",
                  regMobile: "",
                },
                memberDataList: [],
                forData: false,
                showTable: false,
                notMemberMsg: true,
              });
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }





  }

  handleViewDetails = (index) => {
    this.setState({
      isLoading: true,
    });

    if (window.innerWidth <= 768) {
      window.scrollTo({ top: 0, left: 0 });

      this.setState({
        hideBox: true,
        hideTable: true,
      });

      setTimeout(() => this.setState({ isLoading: false }), 1000);
    } else {
      this.setState({
        hideBox: false,
        hideTable: true,
      });

      setTimeout(() => this.setState({ isLoading: false }), 1000);
    }

    //console.log(index);
    if (this.state.memberDataList) {
      this.setState({
        accountDetails: this.state.memberDataList[index].data,
        accountStateData: this.state.memberDataList[index].AccountState,
        showTable: true,
        actived: index,
      });
      localStorage.setItem("index", index);
    } else {
      this.setState({
        accountDetails: [],
        accountStateData: '',
        showTable: false,
      });
      localStorage.setItem("index", "");
    }

    //console.log(this.state.accountDetails);
  };

  // html2canvas(input).then((canvas) => {
  //   const imgData = canvas.toDataURL('image/png');
  //   const pdf = new jsPDF();
  //   pdf.addImage(imgData, 'PNG', 0, 0);
  //   pdf.save("download.pdf");
  // });

  // downloadPDF = () => {

  //   var requestBody = {
  //     memberDetails: this.state.memberDetails,
  //     memberDataList: this.state.memberDataList,
  //     accountDetails: this.state.accountDetails,
  //     actived: this.state.actived
  //   }

  //   console.log(JSON.stringify(requestBody));

  // }

  generatePDF = (memNum, accountNumber, accountType, companyName) => {
    console.log(memNum, accountNumber, accountType, companyName);
    var requestBody = {
      memNum: memNum,
      accountNumber: accountNumber,
      accountType: accountType,
      companyName: companyName,
    };

    axios
      .post("https://statements.vriddhi.org/generate-pdf/", requestBody)
      .then((res) => {
        console.log(res);

        if (res.status === 200) {
          let downLoadFile = res.data.SUCCESS;
          console.log(downLoadFile);
          window.open(downLoadFile, "_blank");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  logout() {
    localStorage.clear();
    this.props.history.push("/");
  }

  handleBack() {
    this.setState({
      isLoading: true,
    });

    if (window.innerWidth <= 768) {
      window.scrollTo({ top: 0, left: 0 });
      this.setState({
        hideBox: false,
        hideTable: false,
      });

      setTimeout(() => this.setState({ isLoading: false }), 1000);
    } else {
      this.setState({
        hideBox: false,
        hideTable: false,
      });

      setTimeout(() => this.setState({ isLoading: false }), 1000);
    }
  }

  showAccountStateData = (data) => {

    let arr = []

    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        arr.push(
          <tr>
            <th width="15%"> {key}</th>
            <td width="2%">:</td>
            <td width="65%">{key == 'Balances as on date' ? moment(data[key]).format('DD-MM-YYYY') : data[key]}</td>
          </tr>
        )
      }
    }

    return arr
  }
  handleChange = (v) => {

    console.log("false")
    this.setState({
        
      isLoading: false,
      // memberno_Flag:false
    });

    console.log(v);
    if (v != null && v.length == 1 && Array.isArray(v) && typeof(v[0])=='string') {
      console.log("test data :" + v);
      this.getDatas(v);
    }
  }
  Paren

  render() {
    // html2canvas(document.body).then(function (canvas) {
    //   // document.body.appendChild(canvas);
    //   if(canvas){
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData, 'PNG', 0, 0);
    //   pdf.save("download.pdf")
    // }
    // });



    return (
      <React.Fragment>
        <Navbar
          expand="xl"
          style={{ boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)" }}
        >
          <Navbar.Brand style={{ margin: "auto" }}>
            {this.state.hideBox ? (
              <Button
                className="backBtn"
                variant="link"
                onClick={() => this.handleBack()}
              >
                <span
                  aria-hidden="true"
                  class="carousel-control-prev-icon"
                ></span>
                Back
              </Button>
            ) : (
              ""
            )}
            <Image src={logo} style={{ maxWidth: "100%" }} />
          </Navbar.Brand>
        </Navbar>

        <Container className="section divIdToPrint" id="divIdToPrint">
          {this.state.isLoading ? (
            <div className="loader_div">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            ""
          )}

          {/* <div ref={ref} id="divIdToPrint"> */}
          <div ref={ref}>
            {/* <Row className="mb_20">
              <Col className="text-center">
                <Image src={logo} style={{ maxWidth: "100%" }} />
                <Button className="logout_btn" variant="primary" onClick={() => this.logout()}>Logout</Button>
              </Col>
            </Row> */}

            {!this.state.hideBox ? (
              <Row className="mb_20">
                <Col xs="12" xl="3" md="12"></Col>
                {localStorage.getItem("memberType") == "member" ? (
                  <Col xs="12" xl="6" md="12"></Col>
                ) : (
                  <Col xs="12" xl="6" md="12">
                    <Row>
                      <Col xl="2" md="2" xs="4">
                        <Form.Label>Member&nbsp;ID:</Form.Label>
                      </Col>
                      <Col xl="6" md="6" xs="8" className="mb_20">
                        {this.state.memberIds === "M00001" || this.state.memberIds === "M00002" || this.state.memberIds === "M00004" || this.state.memberIds === "M00005" || this.state.memberIds === "M00018" || this.state.memberIds === "M00028" || this.state.memberIds === "M52432" || this.state.memberIds === "M00009" || this.state.memberIds === "M55281" || this.state.memberIds === "M00058"
                          ?
                          <>

                            <Form.Control
                              autoComplete="on"
                              type="text"
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  this.handleSubmitMemberNo();
                                }
                              }}
                              onChange={(e) => {
                                if (e.target.value.length > 5) {
                                  this.handleMemberNo("memberno", e.target.value)
                                }
                              }
                              }
                            />
                          </>
                          :
                          <>

                            <AsyncTypeahead
                              onChange={(e) => this.handleChange(e)}
                              id="async-user"
                              allowNew={true}
                              minLength={3}
                              labelKey={'name'}
                              onSearch={query => {
                                this.setState({
                                  isLoading: true,
                                  memberno_Flag: true
                                });

                                let memId = localStorage.getItem("memId");
                                var obj = {
                                  agentId: memId,
                                  searchText: query
                                }
                               
                                axios.post(`https://z8lu2f4zs1.execute-api.ap-south-1.amazonaws.com/dev/searchMembersForAgent`, obj).then(
                                  res => {
                                    console.log("culprit1111")
                                    if (res.status === 200 && manageLoader==false) {
                                      
                                      console.log("culprit")
                                      let data = res.data.body.result.Users;
                                      const memberInfos = data.map((x) => x.MemNum + " - " + x.MemName)
                                      localStorage.setItem("userId", memberInfos)
                                      this.setState({
                                        isLoading: false,
                                        optionsList: memberInfos,
                                        memberno_Flag: true,
                                        memberId: memberInfos[0],
                                        constId: memberInfos,
                                      });

                                    }
                                  }
                                ).catch(
                                  err => {
                                    console.log(err);
                                  }
                                )
                                // }
                                this.handleEvent(query);
                              }}
                              // selected={() => this.handleSubmitMemberNo()}

                              options={this.state.optionsList}
                            // defaultSelected instead of selected, can't use it controlled in a table
                            // defaultSelected={[this.props.defaultValue]}
                            // onChange={this.updateState}
                            // renderMenuItemChildren={(option, props) => (
                            //   <Fragment>
                            //     <span>{this.state.optionsList}</span>
                            //   </Fragment>
                            // )}
                            // renderMenu={(results, menuProps) => (
                            //       <span onClick={() => this.handleSubmitMemberNo()}>{this.state.optionsList}</span>
                            // )}
                            />
                          </>

                        }



                        {this.state.optionsList.length < 0 ? (
                          <div className="error_msg">
                            This Field is Required
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xl="4" md="4" xs="12" className="text-center mb_20">
                        {/* {this.state.constId.length} */}
                        {/* {this.state.isOpenValue} */}
                        {/* {this.state.constId.length === 1 ? 
                        <> */}
                        {/* <Button
                          disabled={false}
                          className="view_details"
                          variant="primary"
                          onClick={() => this.handleSubmitMemberNo()}
                        >
                          View&nbsp;Account&nbsp;Details
                        </Button> */}
                        {/* {this.state.memberIds} */}
                        {/* </> : <>
                        <Button
                          disabled={true}
                            className="view_details"
                            variant="primary"
                            onClick={() => this.handleSubmitMemberNo()}
                          >
                            View&nbsp;Account&nbsp;Details
                        </Button>
                        </>} */}

                      </Col>
                    </Row>
                  </Col>
                )}

                <Col xs="12" xl="3" md="12">
                  <Button
                    className="logout_btn"
                    variant="primary"
                    onClick={() => this.logout()}
                  >
                    Logout
                  </Button>
                </Col>
                {/* <Col xs="12" md="4">

                {this.state.memberDataList != 0 ?
                  <Table>
                    <tr>
                      <th width="30%">Name: </th>
                      <td width="70%">{this.state.memberDetails.memName ? this.state.memberDetails.memName : ''}</td>
                    </tr>
                    <tr>
                      <th>Field Officer: </th>
                      <td>{this.state.memberDetails.fieldOfficer ? this.state.memberDetails.fieldOfficer : ''}</td>
                    </tr>
                    <tr>
                      <th>Center Name: </th>
                      <td>{this.state.memberDetails.centerName ? this.state.memberDetails.centerName : ''}</td>
                    </tr>
                    <tr>
                      <th>Mobile No.: </th>
                      <td>{this.state.memberDetails.regMobile ? this.state.memberDetails.regMobile : ''}</td>
                    </tr>
                    <tr>
                      <th>Member ID:</th>
                      <td>{this.state.memberDetails.memNum ? this.state.memberDetails.memNum : ''}</td>
                    </tr>
                  </Table>
                  : ""
                }
              </Col> */}
              </Row>
            ) : (
              ""
            )}

            <Row className="mb_20">
              <Col xs="12" md="6">
                {this.state.memberDataList != 0 ? (
                  <Table>
                    <tr>
                      <th width="30%">Name </th>
                      <td width="5%">:</td>
                      <td width="65%">
                        {this.state.memberDetails.memName
                          ? this.state.memberDetails.memName
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th>Field Officer</th>
                      <td>:</td>
                      <td>
                        {this.state.memberDetails.fieldOfficer
                          ? this.state.memberDetails.fieldOfficer
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th>Center Name</th>
                      <td>:</td>
                      <td>
                        {this.state.memberDetails.centerName
                          ? this.state.memberDetails.centerName
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th>Mobile No.</th>
                      <td>:</td>
                      <td>
                        {this.state.memberDetails.regMobile
                          ? this.state.memberDetails.regMobile
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th>Member ID</th>
                      <td>:</td>
                      <td>
                        {this.state.memberDetails.memNum
                          ? this.state.memberDetails.memNum
                          : ""}
                      </td>
                    </tr>
                  </Table>
                ) : (
                  ""
                )}
              </Col>
              <Col xs="12" md="6"></Col>
            </Row>

            {this.state.notMemberMsg ? (
              <Alert variant="danger">Member does not exist.</Alert>
            ) : (
              ""
            )}

            {!this.state.hideBox ? (
              <Row
                style={{ rowGap: "20px" }}
                className="mb_20 align-items-stretch cards-wrapper"
              >
                {this.state.memberDataList ? (
                  this.state.memberDataList.map((raw_data, index) => {
                    return (
                      <Col
                        key={index}
                      // xl="2"
                      // lg="3"
                      // md="4"
                      // sm="6"
                      // xs="12"
                      >
                        <div
                          className={
                            index === this.state.actived ? "box active" : "box"
                          }
                          style={{ height: "100%", cursor: "pointer" }}
                          onClick={() => this.handleViewDetails(index)}
                        >
                          <h1>{raw_data.availableBal}</h1>
                          <div>
                            Acc No.: <b>{raw_data.accountNumber}</b>
                          </div>
                          <p>
                            Acc Type: <b>{raw_data.accountType}</b>
                          </p>
                          <div style={{ marginTop: "auto" }}>
                            {/* <Button
                              variant="primary"
                              style={{
                                width: "100%",
                                marginBottom: "5px",
                                marginRight: "5px",
                              }}
                              onClick={() => this.handleViewDetails(index)}
                            >
                              View Statement
                            </Button> */}
                            {/* <Button
                              variant="primary"
                              style={{
                                width: "100%",
                                marginBottom: "5px",
                                marginRight: "5px",
                              }}
                              className="pdf_btn"
                              onClick={() =>
                                this.generatePDF(
                                  this.state.memberDetails.memNum,
                                  raw_data.accountNumber,
                                  raw_data.accountType,
                                  "vriddhi"
                                )
                              }
                            >
                              Vriddhi Statement
                            </Button> */}
                            {/* <Button
                              variant="primary"
                              style={{
                                width: "100%",
                                marginBottom: "5px",
                                marginRight: "5px",
                              }}
                              className="pdf_btn"
                              onClick={() =>
                                this.generatePDF(
                                  this.state.memberDetails.memNum,
                                  raw_data.accountNumber,
                                  raw_data.accountType,
                                  "vishwajith"
                                )
                              }
                            >
                              Vishwajith&nbsp;Statement
                            </Button> */}
                          </div>

                          {/* {index == this.state.actived ?

                            // <Pdf targetRef={ref} filename="code-example.pdf" options={options} x={5} y={5} scale={0.83}>
                            //   {({ toPdf }) => <Button variant="primary" className="pdf_btn" onClick={toPdf}>Download Pdf</Button>}
                            // </Pdf>

                            <Button variant="primary" style={{ marginBottom: "5px", marginRight: "5px" }} className="pdf_btn" onClick={() => this.generatePDF(this.state.memberDetails.memNum, raw_data.accountNumber, raw_data.accountType)}>Download Pdf</Button>

                            // <Button variant="primary" className="pdf_btn" onClick={() => html2canvas()}>Download Pdf</Button>

                            : ""
                          } */}
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <Alert className="text-center" variant="dark">
                    No User Found
                  </Alert>
                )}
              </Row>
            ) : (
              ""
            )}

            {this.state.showTable &&
              this.state.memberDataList &&
              this.state.hideTable ? (
              <>
                {localStorage.getItem("memberType")?.toLowerCase() ? null : (
                  <>
                    <Button
                      variant="primary"
                      style={{
                        marginBottom: "5px",
                        marginRight: "5px",
                      }}
                      className="pdf_btn"
                      onClick={() =>
                        this.generatePDF(
                          this.state.memberDetails.memNum,
                          this.state.memberDataList[this.state.actived]
                            .accountNumber,
                          this.state.memberDataList[this.state.actived]
                            .accountType,
                          "vriddhi"
                        )
                      }
                    >
                      Vriddhi Statement
                    </Button>
                    <Button
                      variant="primary"
                      style={{
                        marginBottom: "5px",
                        marginRight: "5px",
                      }}
                      className="pdf_btn"
                      onClick={() =>
                        this.generatePDF(
                          this.state.memberDetails.memNum,
                          this.state.memberDataList[this.state.actived]
                            .accountNumber,
                          this.state.memberDataList[this.state.actived]
                            .accountType,
                          "vishwajith"
                        )
                      }
                    >
                      Vishwajith&nbsp;Statement
                    </Button>
                  </>
                )}

                {
                  this.state.accountStateData ?
                    <Row className="mb_20 mt_20">
                      <Col>
                        <Table>
                          {
                            this.showAccountStateData(this.state.accountStateData)
                          }

                        </Table>
                      </Col>
                    </Row>
                    : ''
                }
                <Row>
                  <Col>
                    <Table responsive striped hover className="cus_res_table">
                      <thead className="thead">
                        <tr>
                          {/* <th className="text-center">Member ID</th> */}
                          <th className="text-center">Transaction No.</th>
                          <th className="text-center">Transaction Date</th>
                          <th className="text-center">Transaction Type</th>
                          <th className="text-center">Amount</th>
                          <th className="text-center">CrDr</th>
                          <th className="text-center">Available Balance</th>
                        </tr>
                      </thead>

                      {this.state.accountDetails.length ? (
                        <tbody>
                          {this.state.accountDetails
                            .reverse()
                            .map((raw_data) => {
                              return (
                                <tr>
                                  {/* <td className="text-center">{raw_data.memId}</td> */}
                                  <th className="text-center d_none">
                                    Transaction ID
                                  </th>
                                  <td className="text-center">{raw_data.id}</td>
                                  <th className="text-center d_none">
                                    Transaction Date
                                  </th>
                                  <td className="text-center">
                                    <Moment format="DD-MM-YYYY">
                                      {raw_data.Date}
                                    </Moment>
                                  </td>
                                  <th className="text-center d_none">
                                    Transaction Type
                                  </th>
                                  <td className="text-center">
                                    {raw_data.TransactionType}
                                  </td>
                                  <th className="text-center d_none">Amount</th>
                                  <td className="text-center">
                                    {raw_data.Amount}
                                  </td>
                                  <th className="text-center d_none">CrDr</th>
                                  <td className="text-center">
                                    {raw_data.CRDR}
                                  </td>
                                  <th className="text-center d_none">
                                    Available Balance
                                  </th>
                                  <td className="text-center">
                                    {raw_data.accBalAfterTransaction}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="6" className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
          </div>
        </Container>
      </React.Fragment>
    );
  }
}


export default App;
