import React, { useState } from "react";
import App from "./App";
import Login from "./Login";
import LoginOTP from "./LoginOTP";
import { Route, Switch, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

export const Routes = () => {
  // var isLogin = localStorage.getItem("isLogin");
  // console.log(isLogin);

  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        {/* <Route exact path="/dashboard" component={App} /> */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/loginOTP" component={LoginOTP} />
        <PrivateRoute path="/dashboard" component={App} />
      </Switch>
    </div>
  );
};
