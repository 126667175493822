import React, { Component } from "react";
import "./App.css";
import logo from "./vriddhi_logo.png";
import {
  Container,
  Row,
  Col,
  Table,
  Image,
  Form,
  Button,
  Spinner,
  Navbar,
  Nav,
  NavDropdown,
  FormControl,
  Alert,
} from "react-bootstrap";

import axios from "axios";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Mobile_Number: "",
      Mobile_Number_Flag: false,
      Mobile_Number_validFlag: false,
      Mobile_Number_minmaxFlag: false,
      // password: '',
      // password_Flag: false,
      // password_minmaxFlag: false,
      isLoginSuccessMsg: false,
      isLoginErrorMsg: false,
      showLoader: false,
      mobileNotRegMsg: false,
    };

    if (localStorage.getItem("isLogin") == "true") {
      this.props.history.push("/dashboard");
    }
  }

  handleValidation = (key, value) => {
    if (value == "") {
      this.setState({
        [key]: "",
      });
    } else {
      this.setState({
        [key]: value,
      });
    }
  };

  submitLoginForm = () => {
    var isAllowFlag = true;

    if (this.state.Mobile_Number == "") {
      isAllowFlag = false;
      this.setState({
        Mobile_Number_Flag: true,
        Mobile_Number_validFlag: false,
        Mobile_Number_minmaxFlag: false,
      });
    } else if (isNaN(this.state.Mobile_Number)) {
      isAllowFlag = false;
      this.setState({
        Mobile_Number_Flag: false,
        Mobile_Number_validFlag: true,
        Mobile_Number_minmaxFlag: false,
      });
    } else if (this.state.Mobile_Number.length != 10) {
      isAllowFlag = false;
      this.setState({
        Mobile_Number_Flag: false,
        Mobile_Number_validFlag: false,
        Mobile_Number_minmaxFlag: true,
      });
    } else {
      this.setState({
        Mobile_Number_Flag: false,
        Mobile_Number_validFlag: false,
        Mobile_Number_minmaxFlag: false,
      });
    }

    if (isAllowFlag) {
      console.log(this.state.Mobile_Number);

      this.setState({
        showLoader: true,
      });

      localStorage.setItem("mobileNumber", this.state.Mobile_Number);

      var requestNumber = {
        mobile: this.state.Mobile_Number,
      };
      console.log(requestNumber);

      axios
        .post(
          "https://2tmcs4fkn4.execute-api.ap-south-1.amazonaws.com/PROD/loginvriddhi",
          requestNumber
        )
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            this.setState({
              showOtpSuccessMsg: true,
              showLoader: false,
            });

            setTimeout(() => this.props.history.push("/loginOTP"), 500);
            // localStorage.setItem("isLogin", true);
            // setTimeout(() => this.props.history.push("/dashboard"), 500);
          } else {
            this.setState({
              mobileNotRegMsg: true,
              showLoader: false,
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  render() {
    return (
      <Container className="section">
        <Row className="mb_40">
          <Col className="text-center">
            <Image src={logo} style={{ maxWidth: "100%" }} />
          </Col>
        </Row>

        {this.state.isLoginErrorMsg ? (
          <Alert variant="danger">
            Login failed. Email Or Password is invalid.
          </Alert>
        ) : (
          ""
        )}

        {this.state.isLoginSuccessMsg ? (
          <Alert variant="info">Login successfully.</Alert>
        ) : (
          ""
        )}

        {this.state.mobileNotRegMsg ? (
          <Alert variant="info">Mobile number is not registered</Alert>
        ) : (
          ""
        )}

        <Row>
          <Col sm="4"></Col>
          <Col sm="4 text-center">
            <div className="login-box" style={{ width: "100%" }}>
              {this.state.showLoader ? (
                <div className="loader_box">
                  <Spinner animation="border" variant="danger" />
                </div>
              ) : (
                ""
              )}

              <h4 className="mb_20">Login</h4>
              <Form.Control
                type="tel"
                placeholder="Mobile Number"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this.submitLoginForm();
                  }
                }}
                onChange={(e) =>
                  this.handleValidation("Mobile_Number", e.target.value)
                }
              />
              {this.state.Mobile_Number_Flag ? (
                <div className="error_msg">This Field is Required</div>
              ) : (
                ""
              )}
              {this.state.Mobile_Number_validFlag ? (
                <div className="error_msg">Mobile Number is not Valid</div>
              ) : (
                ""
              )}
              {this.state.Mobile_Number_minmaxFlag ? (
                <div className="error_msg">Mobile Number lenght must be 10</div>
              ) : (
                ""
              )}

              <div className="text_right mt_20">
                <Button
                  className="red_btn"
                  variant="primary"
                  onClick={() => this.submitLoginForm()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Col>
          <Col sm="4"></Col>
        </Row>
      </Container>
    );
  }
}

export default Login;
