import React, { Component } from "react";
import "./App.css";
import logo from "./vriddhi_logo.png";
import {
  Container,
  Row,
  Col,
  Table,
  Image,
  Form,
  Button,
  Spinner,
  Navbar,
  Nav,
  NavDropdown,
  FormControl,
  Alert,
} from "react-bootstrap";

import axios from "axios";

class LoginOTP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opt_no: "",
      opt_no_Flag: false,
      opt_no_validFlag: false,
      opt_no_minmaxFlag: false,
      showOtpSuccessMsg: false,
      showOtpErrorMsg: false,
      showLoader: false,
    };

    console.log(localStorage.getItem("isLogin"));

    if (localStorage.getItem("isLogin") == "true") {
      this.props.history.push("/dashboard");
    }
  }

  handleValidation = (key, value) => {
    if (value == "") {
      this.setState({
        [key]: "",
      });
    } else {
      this.setState({
        [key]: value,
      });
    }
  };

  submitOtpForm = () => {
    var isAllowFlag = true;

    if (this.state.opt_no == "") {
      isAllowFlag = false;
      this.setState({
        opt_no_Flag: true,
        opt_no_validFlag: false,
        opt_no_minmaxFlag: false,
      });
    } else if (isNaN(this.state.opt_no)) {
      isAllowFlag = false;
      this.setState({
        opt_no_Flag: false,
        opt_no_validFlag: true,
        opt_no_minmaxFlag: false,
      });
    } else if (this.state.opt_no.length != 4) {
      isAllowFlag = false;
      this.setState({
        opt_no_Flag: false,
        opt_no_validFlag: false,
        opt_no_minmaxFlag: true,
      });
    } else {
      this.setState({
        opt_no_Flag: false,
        opt_no_validFlag: false,
        opt_no_minmaxFlag: false,
      });
    }

    if (isAllowFlag) {
      console.log(this.state.opt_no);
      var mobileNumber = localStorage.getItem("mobileNumber");

      this.setState({
        showLoader: true,
      });
      

      // var reqVipBody = {

      // }

      var requestBody = {
        mobile: mobileNumber,
        otp: this.state.opt_no,
      };

      axios
        .post(
          "https://2tmcs4fkn4.execute-api.ap-south-1.amazonaws.com/PROD/verifyotpvriddhi",
          requestBody
        )
        .then((res) => {
          console.log(res);
          let memId = localStorage.setItem("memId", res.data.memNum)
          if (res.data.status == "success") {
            this.setState({
              showOtpSuccessMsg: true,
              showOtpErrorMsg: false,
            });


            if (res.data.memType != null  && res.data.memType.toLowerCase() == "member") {
              localStorage.setItem("memberno", res.data.memNum);
              // localStorage.setItem(
              //   "memberType",
              //   res.data.memType  
              //     ? res.data.memType.toLowerCase()
              //     : res.data.memType
              // );

              // 22-03-22 Malav

              localStorage.setItem(
                "memberType",
                res.data.memType  != null 
                  ? res.data.memType.toLowerCase()
                  : '' 
              );


              localStorage.setItem("isLogin", true);
              localStorage.setItem("memId", res.data.memNum)
              setTimeout(() => this.props.history.push("/dashboard"), 500);
            } else {
              localStorage.setItem("isLogin", true);

              setTimeout(() => this.props.history.push("/dashboard"), 500);
            }
          } else {
            this.setState({
              showOtpSuccessMsg: false,
              showOtpErrorMsg: true,
              showLoader: false,
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  gotoLogin() {
    this.props.history.push("/login");
  }

  render() {
    return (
      <Container className="section">
        <Row className="mb_40">
          <Col className="text-center">
            <Image src={logo} style={{ maxWidth: "100%" }} />
          </Col>
        </Row>

        {this.state.showOtpErrorMsg ? (
          <Alert variant="danger">OTP verification failed.</Alert>
        ) : (
          ""
        )}

        {this.state.showOtpSuccessMsg ? (
          <Alert variant="info">OTP verified successfully.</Alert>
        ) : (
          ""
        )}

        <Row>
          <Col sm="4"></Col>
          <Col sm="4 text-center">
            <div className="login-box">
              {this.state.showLoader ? (
                <div className="loader_box">
                  <Spinner animation="border" variant="danger" />
                </div>
              ) : (
                ""
              )}

              <h4 className="mb_20">Verify Your OTP</h4>
              <Form.Control
                type="text"
                placeholder="Enter OTP"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this.submitOtpForm();
                  }
                }}
                onChange={(e) =>
                  this.handleValidation("opt_no", e.target.value)
                }
              />
              {this.state.opt_no_Flag ? (
                <div className="error_msg">This Field is Required</div>
              ) : (
                ""
              )}
              {this.state.opt_no_validFlag ? (
                <div className="error_msg">Invalid OTP</div>
              ) : (
                ""
              )}
              {this.state.opt_no_minmaxFlag ? (
                <div className="error_msg">OTP lenght must be 4</div>
              ) : (
                ""
              )}
              <div className="text_right mt_20">
                <Button
                  className="mr_10"
                  variant="link"
                  onClick={() => this.gotoLogin()}
                >
                  Back
                </Button>
                <Button
                  className="red_btn"
                  variant="primary"
                  onClick={() => this.submitOtpForm()}
                >
                  Submit OTP
                </Button>
              </div>
            </div>
          </Col>
          <Col sm="4"></Col>
        </Row>
      </Container>
    );
  }
}

export default LoginOTP;
